/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { CloseOutlined } from '@ant-design/icons';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Avatar } from '@/primitives/Avatar';
import { Button } from '@/primitives/Button';
import { Card } from '@/primitives/Card';
import { Col } from '@/primitives/Col';
import { Flex } from '@/primitives/Flex';
import { Text } from '@/primitives/Text';
import { RelativeTime } from '../RelativeTime';
import { ConfigRevision } from '@/client';

export type ConfigureNodesJobWizardSelectConfigRevisionCodeProps = {
  configRevision: ConfigRevision | undefined;
  setShowConfigCode: (showConfigCode: boolean) => void;
};

export function ConfigureNodesJobWizardSelectConfigRevisionCode({
  configRevision,
  setShowConfigCode,
}: ConfigureNodesJobWizardSelectConfigRevisionCodeProps) {
  if (!configRevision) {
    return null;
  }

  return (
    <Col span={12}>
      <Card padding="sm" style={{ width: '100%' }} gap="xs">
        <Flex align="center" justify="space-between" full>
          <Text type="default" size="md" mono>
            {configRevision.filename}
          </Text>
          <Button dataTestId="close-job-wizard" type="text" onClick={() => setShowConfigCode(false)}>
            <CloseOutlined />
          </Button>
        </Flex>
        <Text
          type="default"
          size="lg"
          strong
        >{`Revision #${configRevision.revisionNumber}: ${configRevision.description}`}</Text>
        <Flex align="center" gap="xs">
          <Avatar text={configRevision.createdBy.displayName[0]} size="xs" dataTestId="display-name" />
          <Text type="subtle" size="sm">
            {configRevision.createdBy.displayName},
          </Text>
          <Text type="subtle" size="sm">
            <RelativeTime date={configRevision!.createdAt} />
          </Text>
          <Text type="subtle" size="sm">
            |
          </Text>
          <Text type="subtle" size="sm" mono copyable>
            {configRevision.id}
          </Text>
        </Flex>
      </Card>
      <div style={{ overflowY: 'auto', fontFamily: 'DM Mono' }}>
        <SyntaxHighlighter
          language="go"
          style={docco}
          showLineNumbers
          lineNumberStyle={{
            paddingLeft: '5px',
            paddingRight: '5px',
            marginRight: '15px',
            height: '26px',
            color: '#191919',
            fontSize: '1rem',
            backgroundColor: 'rgb(25, 25, 25, 0.04)',
            fontFamily: 'DM Mono',
            width: '50px',
          }}
          customStyle={{
            backgroundColor: 'white',
            border: '1px solid rgb(25, 25, 25, 0.1)',
            fontSize: '14px',
            padding: '0',
          }}
        >
          {ensureThirtyLines(configRevision.source)}
        </SyntaxHighlighter>
      </div>
    </Col>
  );
}

function ensureThirtyLines(source = '') {
  const lines = source.split('\n');
  const lineCount = lines.length;

  if (lineCount < 15) {
    const additionalLines = 15 - lineCount;
    for (let i = 0; i < additionalLines; i += 1) {
      lines.push('');
    }
  }

  return lines.join('\n');
}
